import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '',
    routes: [
        {
            path: '/',
            redirect: '/basic-link'
        },
        {
            path: '/basic-link:login?',
            name: 'Limited Integration with Basic Link',
            component: () => import('@/views/BasicLink.vue'),
        },
        {
            path: '/basic-button:login?',
            name: 'Limited Integration with Basic Button',
            component: () => import('@/views/BasicButton.vue'),
        },
        {
            path: '/custom-link:login?',
            name: 'Limited Integration with Custom Link',
            component: () => import('@/views/CustomLink.vue'),
        },
        {
            path: '/seamlessaccess-button:login?',
            name: 'Standard Integration with SeamlessAccess Button',
            component: () => import('@/views/SeamlessAccessButton.vue'),
        },
        {
            path: '/custom-seamlessaccess-button:login?',
            name: 'Custom Integration with SeamlessAccess Button',
            component: () => import('@/views/CustomSeamlessAccessButton.vue'),
        },
        {
            path: '/example-integrations:login?',
            name: 'Advanced Examples',
            component: () => import('@/views/ExampleIntegrations.vue'),
        },
    ],
})

//check if has query param logout location
//redirect to that location with a query param for logout
/*router.beforeEach((to, from, next) => {
    if (to.query.destination) {
        const location = to.query.destination.split('_')
        next({
            path: '/' + location[0] + '?login=yes',
            replace: true
        })
    } else {
        next()
    }
    console.log('from: ', from)
    next()
})*/

export default router
